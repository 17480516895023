import s from './modal.module.css'
import image from './popup_black_friday.webp'
import { Button } from '../../components/Button'
import { SharedProps } from '../../components/ModalDialog/ModalDialog'
import cx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { reachGoal } from '../../utils/metrics'
import { useSubscriptions } from '../../store/useSubscriptions'
import { Trans } from 'react-i18next'

export const MODAL_NAME = 'blackFriday'

export type BlackFridayModalProps = {
  onBuyPlanHandler: () => void
}

export function BlackFridayModal(props: BlackFridayModalProps & SharedProps) {
  const { close, onBuyPlanHandler } = props
  const { t } = useTranslation()
  const hasActiveSubscription = useSubscriptions((s) => s.hasActiveSubscription)

  const onBuyHandler = () => {
    reachGoal('click-black-friday-buy-button')
    close()
    onBuyPlanHandler()
  }

  useEffect(() => {
    reachGoal('open-black-friday-popup')
    hasActiveSubscription ? reachGoal('open-black-friday-popup-with-subscription') : reachGoal('open-black-friday-popup-without-subscription')
  },[])

  const getMinorTextKey = () => {
    return hasActiveSubscription ? 'pages.blackFridayModal.discountOfferWithPlan' : 'pages.blackFridayModal.discountOfferWithoutPlan'
  }

  const getButtonTextKey = () => {
    return hasActiveSubscription ? 'pages.blackFridayModal.activateDiscountButtonWithPlan' : 'pages.blackFridayModal.activateDiscountButtonWithoutPlan'
  }

  return (
    <div className={s.bodyBlackFriday}>
      <img className={cx(s.imgPopup, s.imgPopupBlackFriday)} src={image} />
      <h1 className={cx(s.mainTextPopup, s.blackFridayTitle, 'titleM')}>
        <Trans 
          i18nKey='pages.blackFridayModal.blackFridayHeader'
          components={{
            b: <b />
          }}
        />
        </h1>
      <div className={cx(s.minorTextPopup, s.firstMinorText, 'paragraphM')}>
        <p className={s.firstMinorTextMargin}>
          <Trans 
            i18nKey={getMinorTextKey()}
            components={{
              b: <b className={s.minorText18size}/>
            }}
          />
        </p>
        <p className={s.secondMinorText}>
          <Trans 
            i18nKey='pages.blackFridayModal.offerDeadline'
            components={{
              b: <b />
            }}
          />
        </p>
      </div>
      
      <div className={cx(s.popupButtons, s.popupButtonsBlackFridayTitle)}>
        <Button
          className= {s.blackFridayButton}
          size="m"
          grow={false}
          onClick={() => {
              onBuyHandler()
          }}
        >
          {t(getButtonTextKey())}
        </Button>
      </div>
    </div>
  )
}
