import React, { useEffect, useState } from 'react'
import { ModalDialog } from '../ModalDialog/ModalDialog'
import { PayWallModal, MODAL_NAME as PAYWALL_MODAL_NAME } from '../../ds/modal/PayWallModal'
import { PayWallModalDialogComponent } from '../ModalDialog/PayWallModalDialogComponent'
import { FullScreenSizeContainer } from '../ModalDialog/FullScreenSizeContainer'
import { PlansModal, MODAL_NAME as PLANS_MODAL_NAME } from '../../ds/modal/PlansModal'
import { OverlayModal, MODAL_NAME as OPEN_OVERLAY } from '../../ds/modal/OverlayModal'
import { CreateNewProjectModal, MODAL_NAME as CREATE_NEW_PROJECT } from '../../ds/modal/CreateNewProjectModal'
import { OverlayComponent } from '../ModalDialog/OverlayComponent'
import {
  UserProfileModal,
  MODAL_NAME as USER_PROFILE_MODAL_NAME,
} from '../../ds/modal/UserProfileModal'

import {
  SuccessfullyPaidModal,
  SuccessfullyPaidSubscriptionModal,
  MODAL_NAME as PAID_MODAL_NAME,
  MODAL_NAME_SUBSCRIPTION as PAID_SUBSCRIPTION_MODAL_NAME,
} from '../../ds/modal/SuccessfullyPaidModal'

import {
  BuyCreditsModal,
  MODAL_NAME as BUY_CREDITS_MODAL_NAME,
} from '../../ds/modal/BuyCreditsModal'
import { useModalManager } from '../../store/useModalManager'
import { useCredits } from '../../store/useCredits'
import {useNavigate} from "react-router-dom";
import {R_PLANS, R_SIGN_IN_PAGE, R_START_PAGE} from "../../router-constants";
import { useLocation } from 'react-router-dom';
import {MODAL_NAME as SAVING_MODAL, SavingModal} from "../../ds/modal/SavingModal";
import {DownloadImage} from "../../ds/modal/DownloadImage";
import { useProjects } from '../../store/useProjects'
import { deleteProject } from '../../api/projects/delete-project'
import { useFiles } from '../../store/useFiles'
import { onFileDrop } from '../new-editor/utils/uploadImage.utils'
import { useFullPageLoading } from '../../contexts/FullPageLoadingContext'
import { fetchProjects } from '../../api/projects/fetch-projects'
import { ContactModal, MODAL_NAME as CONTACT_MODAL } from '../../ds/modal/ContactAfterDownloadClickModal'
import { duplicateProject } from '../../api/projects/duplicate-project'
import { useProjectHook } from '../../hooks/useProjectHook'
import { useDuplicatedProjects } from '../../store/useDuplicatedProjects'
import { MODAL_NAME as CONFIRMATION_INFOGRAPHIC } from '../../ds/modal/ConfirmationInfographicsModal'
import { ConfirmationInfographicModal } from '../../ds/modal/ConfirmationInfographicsModal'
import { useInfographics } from '../../hooks/useInfographics'
import { useAddInfographics } from '../../store/useAddInfographics'
import { MODAL_NAME as BLACK_FRIDAY } from '../../ds/modal/BlackFridayModal'
import { BlackFridayModal } from '../../ds/modal/BlackFridayModal'
import usePromocodeHook from '../../hooks/usePromocodeHook'
import { BLACKFRIDAY_2024 } from '../new-editor/constants/promocode'
import { usePlans } from '../../store/usePlans'
import { BLACKFRIDAY_PLAN_TAB } from '../new-editor/constants/promocode'

export type AppLevelModalsProps = {
  onFileUploaded?: (url: string) => void
}

export function AppLevelModals(props: AppLevelModalsProps) {
  const { onFileUploaded } = props
  const toggleModal = useModalManager((s) => s.toggleModal)
  const credits = useCredits((s) => s.credits)
  const location = useLocation();
  const navigate = useNavigate()
  const { getOldestProject, resetActiveProject } = useProjects();
  const { getFiles, getUseRemoveBackground } = useFiles()
  const { setLoading } = useFullPageLoading()
  const duplicatedProjectId: string | null = useDuplicatedProjects((s) => s.duplicatedProjectId)
  const fetchProjectsFn = useProjectHook()
  const { resetDuplicatedProjectId } = useDuplicatedProjects();
  const { applyInfographics } = useInfographics()
  const onClickTemplate = useAddInfographics(s => s.onClickTemplate)
  const onApplyCodeHandler = usePromocodeHook()
  const setPlanTab = usePlans((s)=> s.setPlanTab)

  const onBuyCreditsHandler = async () => {
    navigate(R_PLANS)
  }

  const onClickCreateNewCard = () => navigate(R_START_PAGE, { replace: true })

  const onClickCreateNewProject = () => toggleModal(CREATE_NEW_PROJECT, true)

  const onConfirmationInfographics = () => {
    applyInfographics(onClickTemplate)
  }

  const confirmEditorAction = () => {
    resetActiveProject()
    setLoading(false)
    navigate(R_START_PAGE)
  }

  const confirmUploaderAction = async () => {
    if(duplicatedProjectId) {
      await duplicateProject(duplicatedProjectId)
      resetDuplicatedProjectId()
      await fetchProjectsFn()
      setLoading(false)
      return
    }
    const file = getFiles()
    if(file){
      const useRemoveBackground = getUseRemoveBackground()
      onFileDrop(file, useRemoveBackground).then((image) => {
        if (onFileUploaded && image?.imageUrl) {
          onFileUploaded(image.imageUrl)
        }
      }).catch((error) => {
        console.error('Error processing the file in AppLevelModals:', error)
      }).finally (() => {
        setLoading(false)
      })
    }
  }

  const deleteOldestProject = () => {
    const oldestProject = getOldestProject()
    if(oldestProject){
      deleteProject(oldestProject.id)
      fetchProjects()
    }
  }

  const onClickConfirmCreateNewProject = () => {
    setLoading(true)
    deleteOldestProject()
    if(location.pathname === R_START_PAGE){
      confirmUploaderAction()
    } else {
      confirmEditorAction()
    }
    toggleModal(CREATE_NEW_PROJECT, false)
  }
    
  const onBuyPlanHandler = () => {
    setPlanTab(BLACKFRIDAY_PLAN_TAB)
    onApplyCodeHandler(BLACKFRIDAY_2024)
    navigate(R_PLANS)
  }

  useEffect(() => {

    if (credits !== null && credits == 0 && ![R_PLANS, R_SIGN_IN_PAGE].includes(location.pathname)) {
      toggleModal(BUY_CREDITS_MODAL_NAME, true)
    }
  }, [credits])

  return (
    <>
      <ModalDialog
        name={PLANS_MODAL_NAME}
        ContainerComponent={FullScreenSizeContainer}
        render={(p) => <PlansModal {...p} />}
      />
      <ModalDialog
        name={BUY_CREDITS_MODAL_NAME}
        render={(p) => <BuyCreditsModal {...p} onBuyClick={onBuyCreditsHandler} />}
      />

      <ModalDialog
        name={USER_PROFILE_MODAL_NAME}
        ContainerComponent={FullScreenSizeContainer}
        render={(p) => <UserProfileModal {...p} />}
      />
      <ModalDialog name={PAID_MODAL_NAME} render={(p) => <SuccessfullyPaidModal {...p} />} />
      <ModalDialog
        name={PAID_SUBSCRIPTION_MODAL_NAME}
        render={(p) => <SuccessfullyPaidSubscriptionModal {...p} />}
      />

      <ModalDialog
        name={PAYWALL_MODAL_NAME}
        ContainerComponent={PayWallModalDialogComponent}
        render={(p) => <PayWallModal {...p} onBuyClick={onBuyCreditsHandler} onClickCreateNewProject={onClickCreateNewProject} />}
      />

      <ModalDialog
        ContainerComponent={OverlayComponent}
        name={OPEN_OVERLAY}
        render={(p) => <OverlayModal {...p} />}
      />

      <ModalDialog
          name={'downloadImage'}
          render={(p) => (
              <DownloadImage
                  {...p}
                  onCreateNewCard={onClickCreateNewCard}
                  onBuyClick={onBuyCreditsHandler}
              />
          )}
      />

      <ModalDialog
        name={SAVING_MODAL}
        render={(p) => <SavingModal {...p} onProceed={() => navigate(R_START_PAGE)} />}
      />

      <ModalDialog
        name={CREATE_NEW_PROJECT}
        render={(p) => <CreateNewProjectModal {...p} onBuyClick={onBuyCreditsHandler} onClickConfirmCreateNewProject={onClickConfirmCreateNewProject} />}
      />

      <ModalDialog
        name={CONTACT_MODAL}
        render={(p) => <ContactModal {...p} onBuyClick={onBuyCreditsHandler} />}
      />

      <ModalDialog
        name={CONFIRMATION_INFOGRAPHIC}
        render={(p) => <ConfirmationInfographicModal {...p} onConfirmationInfographics={onConfirmationInfographics} />}
      />

      <ModalDialog
        name={BLACK_FRIDAY}
        render={(p) => <BlackFridayModal {...p} onBuyPlanHandler={onBuyPlanHandler} />}
      />
    </>
  )
}
