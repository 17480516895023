import { PromoCode } from "../domain/types/PromoCode"
import { checkPromoCode } from "../api/check-promocode"
import { usePromocode } from "../store/usePromocode"
import { useTranslation } from "react-i18next"


export const usePromocodeHook = () => {
    const applyPromocode = usePromocode((s) => s.setPromocode)
    const setError = usePromocode((s) => s.setError)
    const { t } = useTranslation()

    const lookupPlanIds = (v: null | undefined | string[]): null | string[] => {
        if (!v) {
          return null
        }
      
        if (v.length > 0) {
          return v
        }
      
        return null
      }

    const onApplyCodeHandler = async (promocode: string) => {
        const result: PromoCode = await checkPromoCode(promocode)
    
        if (!result.is_valid) {
          setError(t('pages.promocode.invalidPromoCode'))
          return
        }
    
        applyPromocode(result.code!, result.percent!, lookupPlanIds(result.plans_ids))
      }

    return onApplyCodeHandler
}

export default usePromocodeHook