import { create } from 'zustand'

type Plan = 'yearly' | 'monthly' | 'shop'

export type PlanState = {
  planTab: Plan
}

export type PlanActions = {
  setPlanTab: (plan: Plan) => void
  resetPlanTab: () => void
}

export const usePlans = create<PlanState & PlanActions>()((set) => ({
  planTab: 'monthly',
  resetPlanTab: () =>
    set(() => ({
      planTab: 'monthly',
    })),
  setPlanTab: (plan: Plan) =>
    set(() => ({
      planTab: plan,
    })),
}))
