import { useEffect, useState } from 'react'
import { FileUploadScreen } from '../screens'
import { StartPageLayout } from '../components/layouts'
import Header from '../components/Header/Header'
import { useProjects } from '../store/useProjects'
import { fetchProjects } from '../api/projects/fetch-projects'
import { ProjectList } from '../components/projects'
import {useNavigate} from 'react-router-dom'
import { createProject } from '../api/projects/create-project'
import s from './page-styles/StartPage.module.css'
import cx from 'clsx'
import { deleteProject } from '../api/projects/delete-project'
import { duplicateProject } from '../api/projects/duplicate-project'
import { MenuHandlers } from '../components/projects/CardProject/CardProject'
import { NoProjects } from '../components/projects/NoProjects/NoProjects'
import { ProjectsError } from '../components/projects/ProjectsError/ProjectsError'
import { useSubscriptions, PaywallVariations } from "../store/useSubscriptions";
import { useModalManager } from "../store/useModalManager";
import { MODAL_NAME as PAYWALL_MODAL } from "../ds/modal/PayWallModal";
import { LoadingIndicator } from '../components/projects/LoadingIndicator/LoadingIndicator'
import { ProductVideoModal } from '../ds/modal/ProductVideoModal'
import { useAuthContext } from '../contexts/AuthProvider'
import { VideoModalDialogComponent } from '../components/ModalDialog/VideoModalDialogComponent'
import { useResize } from '../hooks/useResize'
import { useTranslation } from 'react-i18next';
import { isRuLocale } from '../components/new-editor/utils/localization.utils'
import { useProjectHook } from '../hooks/useProjectHook'
import { useDuplicatedProjects } from '../store/useDuplicatedProjects'
import { BlackFridayModal } from '../ds/modal/BlackFridayModal'
import { usePlans } from '../store/usePlans'
import usePromocodeHook from '../hooks/usePromocodeHook'
import { BLACKFRIDAY_PLAN_TAB }  from '../components/new-editor/constants/promocode'
import { BLACKFRIDAY_2024 }  from '../components/new-editor/constants/promocode'
import { R_PLANS } from '../router-constants'
import { ModalComponent } from '../components/ModalDialog/ModalComponent'

export function StartPage() {
  const projects = useProjects((s) => s.projects)
  const { setProjects } = useProjects()
  const { resetDuplicatedProjectId, setDuplicatedProjectId } = useDuplicatedProjects()
  const [isFetched, setFetched] = useState(false)
  const [isError, setError] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isOpenProductVideoModal, setIsOpenProductVideoModal] = useState(true)
  const [isOpenBlackFridayModal, setIsOpenBlackFridayModal] = useState<boolean>(!localStorage.getItem('shownBlackFridayModal'))
  const { hasAuthenticated, isTimeToShowTheVideo } = useAuthContext()
  const [isOpenMobileMenu, setOpenMobileMenu] = useState(false)
  const { t } = useTranslation()
  const fetchProjectsFn = useProjectHook()

  const navigate = useNavigate()
  useEffect(() => {
    ; (async () => {
      try {
        setError(false)
        await fetchProjectsFn()
        setFetched(true)
      } catch (e) {
        setError(true)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const hasActiveSubscription = useSubscriptions(s => s.hasActiveSubscription)
  const toggleModal = useModalManager((s) => s.toggleModal)
  const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)
  const hasPremiumSubscription = useSubscriptions((s) => s.hasPremiumSubscription)
  const hasBusinessSubscription = useSubscriptions((s) => s.hasBusinessSubscription)
  const projectsCount = useProjects(s => s.projects.length)
  const { isScreenSm } = useResize()
  const setPlanTab = usePlans((s)=> s.setPlanTab)
  const onApplyCodeHandler = usePromocodeHook()

  const projectMenuHandlers: MenuHandlers = {
    onDuplicate: async (pid) => {
      setDuplicatedProjectId(pid)
      if (projectsCount >= 100 && !hasBusinessSubscription) {
        setPaywallVariation(PaywallVariations.createMoreBusiness)
        toggleModal(PAYWALL_MODAL, true)
      } else if (projectsCount >= 10 && !hasPremiumSubscription) {
        setPaywallVariation(PaywallVariations.createMorePremium)
        toggleModal(PAYWALL_MODAL, true)
      } else if (!hasActiveSubscription) {
        setPaywallVariation(PaywallVariations.createMore)
        toggleModal(PAYWALL_MODAL, true)
      } else {
        await duplicateProject(pid)
        resetDuplicatedProjectId()
        await fetchProjectsFn(false)
      }
    },

    onDelete: async (pid: string) => {
      await deleteProject(pid)
      await fetchProjectsFn(false)
    },
  }

  const handleFileUploaded = async (url: string) => {
    const project = await createProject(
      {
        sourceImage: url,
      },
      {
        coverImage: url,
      }
    )
    await fetchProjectsFn()
    navigate(`/projects/${project.id}`)
  }

  const setLocalStorageProductVideo = () => {
    localStorage.setItem('viewedVideoAboutProduct', 'true')
  }

  const setLocalStorageBlackFridayModal = () => {
    localStorage.setItem('shownBlackFridayModal', 'true')
  }

  const closeProductVideoModal = () => {
    setIsOpenProductVideoModal(false)
  }

  const closeBlackFridayModal = () => {
    setLocalStorageBlackFridayModal()
    setIsOpenBlackFridayModal(false)
  }

  const onBuyPlanHandler = () => {
    setIsOpenBlackFridayModal(false)
    setPlanTab(BLACKFRIDAY_PLAN_TAB)
    onApplyCodeHandler(BLACKFRIDAY_2024)
    navigate(R_PLANS)
  }

  // FIXME fix props for FileUploadScreen
  return (
    <StartPageLayout header={<Header isOpenMobileMenu={isOpenMobileMenu} setOpenMobileMenu={setOpenMobileMenu}/>} isOpenMobileMenu={isOpenMobileMenu} onFileUploaded={handleFileUploaded}>
      <div className={s.PageLayout}>
        <FileUploadScreen onFileUploaded={handleFileUploaded} />
        {isLoading && (
          <div className={s.LoadingContainer}>
            <LoadingIndicator />
          </div>
        )}
        <div className={s.ProjectListContainer}>
          {isFetched && !isLoading && (
            <div>
              {projects.length > 0 && (
                <div>
                  <h2 className={cx(s.ProjectHeader, isScreenSm ? 'titleS' : 'bodyL')}>{t('pages.fileUploadScreen.projects')}</h2>

                  <ProjectList items={projects} handlers={projectMenuHandlers} />
                </div>
              )}
            </div>
          )}
          {isFetched && !isLoading && <div>{projects.length == 0 && <NoProjects />}</div>}
          {isError && (
            <div>
              <ProjectsError onReload={fetchProjects} />
            </div>
          )}
        </div>
      </div>
      {isRuLocale() && isOpenBlackFridayModal && hasAuthenticated && isTimeToShowTheVideo && !Boolean(localStorage.getItem('shownBlackFridayModal')) && (
        <ModalComponent close={closeBlackFridayModal}>
          <BlackFridayModal onBuyPlanHandler={onBuyPlanHandler} close={closeBlackFridayModal}/>
        </ModalComponent>
      )}
      {isOpenProductVideoModal && hasAuthenticated && isTimeToShowTheVideo && !Boolean(localStorage.getItem('viewedVideoAboutProduct')) && Boolean(localStorage.getItem('shownBlackFridayModal')) && (
        <VideoModalDialogComponent setLocalStorage={setLocalStorageProductVideo} close={closeProductVideoModal}>
          <ProductVideoModal />
        </VideoModalDialogComponent>
      )}
    </StartPageLayout>
  )
}
