import { useState } from 'react'
import s from './InputPromoCodeBlock.module.css'
import { Button } from '../../Button'
import { usePromocode } from '../../../store/usePromocode'
import cx from 'clsx'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check_circle.svg'
import { usePromocodeHook } from '../../../hooks/usePromocodeHook'

type PromoCodeButtonProps = {
  text: string
  onClick: () => void
}

export function InputPromoCodeBlock() {
  const { t } = useTranslation()
  const [isShown, setShown] = useState(false)
  const isApplied = usePromocode((s) => s.isApplied)
  const [promocode, setPromocode] = useState('')
  const setError = usePromocode((s) => s.setError)
  const error = usePromocode((s) => s.error)
  const onApplyCodeHandler = usePromocodeHook()

  const PromoCodeButton = (props: PromoCodeButtonProps) => {
    const { text, onClick } = props
    return (
      <div onClick={onClick} className={s.ActionButton}>
        {text}
      </div>
    )
  }
  
  const AppliedCodeBlock = () => (
    <div className={s.AppliedBlock}>
      <div className={s.checkIcon}>
        <CheckIcon />
      </div>
      <div className={s.AppliedCodeText}>{t('pages.promocode.promoCodeAppliedSuccessfully')}</div>
    </div>
  )

  if (isApplied) {
    return (
      <div className={s.Wrapper}>
        <div className={s.FlexBlock}>
          <AppliedCodeBlock />
        </div>
      </div>
    )
  }

  const applyClickHandler = () => {
    onApplyCodeHandler(promocode)
  }

  const cancelClickHandler = () => {
    setError(null)
    setShown(false)
  }

  return (
    <div className={s.Wrapper}>
      {!isShown && (
        <div>
          <PromoCodeButton onClick={() => setShown(true)} text={t('pages.promocode.iHavePromoCode')} />
        </div>
      )}

      {isShown && (
        <div>
          <div>
            <div>
              <div className={s.InputBlock}>
                <div>
                  <input
                    type="text"
                    className={cx(s.input, 'bodyM')}
                    value={promocode}
                    onChange={(e) => setPromocode(e.target.value)}
                    placeholder={t('pages.promocode.enterPromoCode')}
                  />

                  {error && (
                    <div>
                      <p className={`paragraphS colorAccentPinkDark ${s.errorText}`}>{error}</p>
                    </div>
                  )}
                </div>
                <Button size="m" variation="tertiary" grow={false} onClick={applyClickHandler}>
                  {t('pages.promocode.apply')}
                </Button>
              </div>
            </div>

            <div className={s.ButtonBlock}>
              <PromoCodeButton onClick={cancelClickHandler} text={t('pages.promocode.cancel')} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
