import cx from "clsx";
import s from "../UploadPanel.module.css";
import React, {useCallback, useState} from "react";
import {useDropzone} from "react-dropzone";
import {nanoid} from "nanoid";
import { ReactComponent as CloudUpload } from '@material-design-icons/svg/round/cloud_upload.svg'
import { ReactComponent as CloseIcon } from '@material-design-icons/svg/round/close.svg'
import {Button} from "../../../Button";
import {resizeImage} from "../../../../utils/resizeImage";
import {backendUrl} from "../../../../config/app";
import {useFullPageLoading} from "../../../../contexts/FullPageLoadingContext";
import { useTranslation } from 'react-i18next'
import { ReactComponent as ArrowLeft } from '@material-design-icons/svg/round/chevron_left.svg'
import { useClosePanelInMobile } from '../../../../hooks/useClosePanelInMobile'
import { RemoveBackgroundToggle } from "../../components/RemoveBackgroundToggle";

type PanelProps = {
    store: any
    addImage: (store: any, imageUrl: string, isNewPage?:boolean) => Promise<void>
    isScreenDesktop: boolean
}

interface FileWithPreview extends File {
    preview: string
    id: string
}

const MAX_FILES = 9

const MultiAssetPanel = ({store, addImage, isScreenDesktop}: PanelProps) => {
    const [files, setFiles] = useState<FileWithPreview[]>([])
    const { setLoading } = useFullPageLoading()
    const { t } = useTranslation()
    const closePanelInMobile = useClosePanelInMobile()
    const [removeBackground, setRemoveBackground] = useState(true)

    const handleToggleChange = () => setRemoveBackground(!removeBackground)

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/*': [],
        },
        multiple: true,
        maxFiles: 100, //Limit handled in code
        noDragEventsBubbling: true,
        onDrop: (acceptedFiles) => {
            const limitedAcceptedFiles = acceptedFiles.slice(0, MAX_FILES - files.length)
            setFiles([
                ...files,
                ...limitedAcceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        id: nanoid(),
                    })
                ),
            ])
        },
    })
    const thumbs = files.map((file, index) => (
        <div className={s.upload_preview} key={file.id}>
            <div className={s.upload_delete} onClick={() => handleRemove(index, file)}>
                <CloseIcon />
            </div>
            <div className={s.upload_inner}>
                <img
                    src={file.preview}
                    className={s.preview_image}
                    onLoad={() => {
                        //URL.revokeObjectURL(file.preview)
                    }}
                />
            </div>
        </div>
    ))

    const handleRemove = useCallback((index: number, file: FileWithPreview) => {
        setFiles((prevFiles) => {
            const newFiles = prevFiles.filter((_, i) => i !== index)
            URL.revokeObjectURL(file.preview)
            return newFiles
        })
    }, [])

    const onUploadSlides = async () => {
        if (!files.length) return
        setLoading(true)
        const formData = new FormData()

        for (const file of files) {
            if (file.type === 'image/svg+xml') {
                formData.append('file', file)
            } else {
                const resizedFile = await resizeImage(file, 2400)
                formData.append('file', resizedFile)
            }
        }

        try {
            const response = await fetch(
                `${backendUrl()}api/image/uploadMultiple?removeBackground=${removeBackground}`,
                {
                    method: 'POST',
                    body: formData,
                }
            )

            if (!response.ok) {
                setLoading(false)
                return
            }

            const data = await response.json()
            if (data.images && data.images.length) {
                await store.history.transaction(async () => {
                    for (const imageUrl of data.images) {
                        await addImage(store, imageUrl, true)
                    }
                });
            }
            closePanelInMobile()
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error('Error uploading files:', error)
        }
    }

    const onBackButtonClick = () =>{
        closePanelInMobile()
    }

    return (
        <div className="tool-panel">
            <div className="tool-panel_content">
                <div className={cx('tool-panel_content_text', s.panel_heading)}>
                    <div className='backButton' onClick={() =>
                        onBackButtonClick()
                    }>
                        <ArrowLeft />
                    </div>
                    <h2 className={`${isScreenDesktop ? 'titleS' : 'bodyL'} colorTextBlackPrimary`}>{t('pages.editor.photoFunnel')}</h2>
                </div>
                <div className={s.content_wrapper}>
                    <div className={cx(s.beta, 'colorAccentVioletDefault')}>
                        <h3 className="">
                            {t('pages.editor.betaVersion')}
                        </h3>
                        <div className={cx(s.betaText)}>
                            {t('pages.editor.saveDuplicateNotice')}
                        </div>
                    </div>
                    <span className={cx('colorTextBlackPrimary', 'paragraphM', s.aboutText)}>
                        {t('pages.editor.createSlideSet')}
                    </span>
                    <span className={cx('colorTextBlackPrimary', 'paragraphM', s.aboutText)}>
                        {t('pages.editor.uploadProductPhotos')}
                    </span>
                    {files.length == 0 && (
                        <div
                            {...getRootProps({
                                className: cx('dropzone', s.upload_container, { 'drag-active': isDragActive }),
                            })}
                        >
                            <input {...getInputProps()} />
                            <CloudUpload className={s.upload_icon} />
                            <div className={cx('paragraphM', 'colorTextBlackTertiary')}>
                                {t('pages.editor.dragOrSelectImagesLine1')}
                                <a className={cx(s.upload_link, 'colorAccentVioletDefault')}>
                                    {t('pages.editor.dragOrSelectImagesLine2')}
                                </a>
                            </div>
                        </div>
                    )}
                    {files.length > 0 && (
                        <div className={s.thumbs_container}>
                            {thumbs}
                            {files.length < MAX_FILES && (
                                <div
                                    {...getRootProps({
                                        className: cx('dropzone', s.upload_button, { 'drag-active': isDragActive }),
                                    })}
                                >
                                    <input {...getInputProps()} /> <CloudUpload className={s.upload_button_icon} />
                                </div>
                            )}
                        </div>
                    )}

                    <RemoveBackgroundToggle removeBackground={removeBackground} handleToggleChange={handleToggleChange} />
                    <Button
                        type="button"
                        variation="tertiary"
                        size="m"
                        disabled={files.length == 0}
                        grow={true}
                        onClick={onUploadSlides}
                        style={{ flexShrink: '0', marginTop: '12px' }}
                    >
                        <div className={s.add_images_button}>
                            <div>
                                <span>{t('pages.editor.addSlides')}</span>
                            </div>
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default MultiAssetPanel